<!-- 培训学员考勤 -->
<template>
    <div class="StudentClockIn">
        <el-form ref="form" :model="form" inline class="form" label-width="120px">
            <el-form-item label="日期查询">
                <el-date-picker
                value-format="yyyy-MM-dd"
                    v-model="form.dateQuery"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="授课状态">
                <el-select v-model="form.lecture" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.lectureOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="制单状态">
                <el-select v-model="form.system" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.systemOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键词"></el-input>
            </el-form-item>
        </el-form> 
        <div class="" style="display: flex;justify-content: space-between;">
            <div class="" style="margin-left:22px ;">
                <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                    @click="refresh">
                    <!-- 刷新 -->
                </el-button>
                <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                    @click="newlyAdded">
                    新增
                </el-button>
                 <!-- <button class="print">打印预览</button> -->
                 <button class="more" @click="more">更多</button>
            </div>
            <div class="" style="margin-right: 10px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                    重置
                </el-button>
            </div>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.8%;flex: 1;" 
        height="650" @selection-change="checkAll"  @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="is_approve" sortable="custom" label="审核状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.is_approve==1">已审核</span>
                    <span v-else-if="scope.row.is_approve==2">未审核</span>
                </template>
            </el-table-column>
            <el-table-column prop="number" sortable="custom" label="单号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="class_number" sortable="custom" label="班级编号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="class_name" sortable="custom" label="班级名称" align="center" width="300">
            </el-table-column>
            <el-table-column prop="resources" sortable="custom" label="授课老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="class_resources" sortable="custom" label="应授课老师" align="center" width="150">
            </el-table-column>
            <el-table-column prop="abstract" sortable="custom" label="课次" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course" sortable="custom" label="学科" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course_date" sortable="custom" label="上课日期" align="center" width="120">
            </el-table-column>
            <el-table-column prop="course_time" sortable="custom" label="上课时间" align="center" width="120">
            </el-table-column>
            <el-table-column prop="class_size" sortable="custom" label="本班人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="sign_type1" sortable="custom" label="签到人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="sign_type2" sortable="custom" label="试学人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="sign_type3" sortable="custom" label="补课人数" align="center" width="120">
            </el-table-column>
            <el-table-column prop="attendance" sortable="custom" label="出勤率(%)" align="center" width="120">
            </el-table-column>
            <el-table-column prop="classroom" sortable="custom" label="上课教室" align="center" width="200"
            :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="workload1" sortable="custom" label="工作量-1" align="center" width="120">
            </el-table-column>
            <el-table-column prop="workload2" sortable="custom" label="工作量-2" align="center" width="120">
            </el-table-column>
            <el-table-column prop="add_name" sortable="custom" label="录入人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="document_time" sortable="custom" label="制单日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="add_time" sortable="custom" label="录入日期" align="center" width="200">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="280">
                <template slot-scope="scope">
                    <el-button class="stu" size="mini" @click="trainee(scope.$index, scope.row)">
                        学员
                    </el-button>
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;" 
                     @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button  icon="el-icon-delete" size="mini" style="background:#E74C3C;color: #fff;"
                     @click="del(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="" style="display: flex; justify-content: space-between;">
            <div class="" style="margin-left: 22px;">
                <div class="txtbox">
                    工作量1合计 <input type="text" class="itp" :value="workOne" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    工作量2合计 <input type="text" class="itp" :value="workTwo" disabled="disabled" />
                </div>
                <div class="txtbox" style="color: #D51010;">
                    （注：工作量-2 为不含试学学员的工作量） 
                </div>
            </div>
            <!--分页 -->
            <el-pagination @size-change="handleSizeChange"
             @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                   dateQuery:"",//日期查询
                   lecture:0,//授课状态
                   lectureOpt:[
                       {label: "全部", value: 0},
                       {label: "授课", value: 1},
                       {label: "代课", value: 2},
                   ],//授课状态选项
                   system:0,//制单状态
                   systemOpt:[
                       {label: "全部", value: 0},
                       {label: "制单", value: 1},
                       {label: "确认", value:2},
                       {label: "不确认", value: 3}
                   ],//制单状态选项
                   search:"",//搜索
                },
                tableData:[],
                currentPage: 1,
                currentLimit:20,
                total: 0,
                workOne:"",//工作量1合计
                workTwo:"",//工作量2合计
                id:'',//接收id
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods:{
            getList(){
                this.$request({
                    url:'/api/classsign/list',
                    method:'POST',
                    data:{
                        page:this.currentPage,
                        limit:this.currentLimit
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.tableData=res.data.list
                        this.total=res.data.count
                        this.workOne=res.data.workload1_sum
                        this.workTwo=res.data.workload2_sum
                    }
                })
            },
            query(){//查询
                this.currentPage=1
                this.getList()
            },
            empty(){//重置
                this.form.dateQuery=''//查询日期
                this.form.lecture=0//授课状态
                this.form.system=0//制单状态
                this.form.search=''//搜索
                this.getList()
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            checkAll(selection){//选择id
                // console.log(selection)
                this.id = ''
                selection.forEach(item => {
                    this.id = item.id
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addStudentClockIn"
                })
            },
            more(){//更多
                if(this.id!=''){
                    this.$router.push({
                        path: "studentClockInMore",
                        query:{
                            class_id:this.id
                        }
                    })
                }else{
                    this.$message({
                        message: '班级id不能为空，请选择',
                        type: 'error'
                    })
                }
            },
            edit(index,row) { //编辑
                this.$router.push({
                    path: "editStudentClockIn",
                    query:{
                        id:row.id
                    }
                })
            },
            trainee(index,row){//学员
                this.$router.push({
                    path: "pupil",
                    query:{
                        schedule_id:row.schedule_id,
                        class_id:row.class_id
                    }
                })
            },
            del(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/classsign/del',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.tableData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
        
    }
</script>

<style scoped="scoped">
    .StudentClockIn{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    /* 刷新 新增 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 打印预览 */
    .print{
        display: inline-block;
        padding-left: 10px;
        width: 94px;
        height: 30px;
        border-radius: 3px;
        vertical-align: middle;
        background: url(../../assets/print.png)8px 9px no-repeat #18BC9C;
        border: none;
        color: #FFFFFF;
        margin:0px 10px;
        cursor: pointer;
    }

    /* 更多 */
    .more{
        display: inline-block;
        width: 66px;
        height: 30px;
        padding-left: 20px;
        vertical-align: middle;
        background:url(../../assets/more.png) 8px 9px no-repeat #29726C;
        color: #FFFFFF;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        margin-left: 10px;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    .stu{
        display: inline-block;
        padding-left: 35px;
        background: url(../../assets/stud.png)10px 7px no-repeat #FF7300;
        color: #FFFFFF;
        font-size: 12px;
    }
    .txtbox{
        height: 19px;
        display: inline-block;
        margin-bottom: 18px;
        margin-right: 10px;
        font-size: 14px;
        color: #000000;
    }
    .itp{
        /* border: none; */
        height: 28px;
        outline: none;
        font-size: 14px;
        background-color: #FFFFFF;
        border: 1px solid #ccc;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>